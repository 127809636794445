import React, {memo} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import useForm from "../../Lib/form/useForm";
import {AccountRecord, AccountSchema} from "../../Data/account";
import Button from "../../Lib/material-kit/components/CustomButtons/Button";
import {addAccount, updateAccount} from "../../Api/accounts";
import ContentBox from "../../Components/ContentBox/ContentBox";
import Grid from "@material-ui/core/Grid";
import Section from "../../Lib/form/Components/Section/Section";
import ValidatedInput from "../../Lib/form/Components/ValidatedInput/ValidatedInput";
import ValidatedRadioGroup from "../../Lib/form/Components/ValidatedRadio/ValidatedRadioGroup";
import ValidatedRadio from "../../Lib/form/Components/ValidatedRadio/ValidatedRadio";
import ValidatedCheckbox from "../../Lib/form/Components/ValidatedCheckbox/ValidatedCheckbox";
import ValidatedSelect from "../../Lib/form/Components/ValidatedSelect/ValidatedSelect";
import {useClubs} from "../../Api/clubs";
import ValidatedSubmit from "../../Lib/form/Components/ValidatedSubmit/ValidatedSubmit";
import {A, navigate} from "hookrouter";
import ValidatedDate from "../../Lib/form/Components/ValidatedDateTime/ValidatedDate";
import {closeSnack} from "../../Main/util";
import {Lock} from "@material-ui/icons";
import {makeStyles} from "@material-ui/styles";
import accountStyle from "./accountStyle";

const useStyles = makeStyles(accountStyle);

/**
 * Displays a list of invalid fields to let the user know why the
 * save button is unavailable.
 */
const ValidationInfo = ({statuses}) => {
  const {t} = useTranslation("account");

  const invalids = Object
      .keys(statuses)
      .filter(fieldName => statuses[fieldName] !== "valid")
      .map(fieldName => t(fieldName));

  if (invalids.length === 0) return null;

  return <Section title={t('invalid-fields')} notes={t('invalid-fields-explanation')}>
    <br/>
    {invalids.join(', ')}
  </Section>
};

/**
 * Checks if the password verification is the same as password.
 */
export const passwordValidator = (fieldName, fieldValue, fieldValues) => {
  return fieldValues["credentials"] === fieldValue;
};

export const Form = memo(({view, id, path, record}) => {

  const {t} = useTranslation("account");
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const classes = useStyles();

  const form = useForm({
    recordType: AccountRecord,
    recordSchema: AccountSchema,
    namespace: 'account',
    record
  });

  console.log(record.toJSON());

  /**
   * wraps addAccount:
   *   - sets default fields not displayed in the form
   *   - catches 406 error
   */
  const add = (record, onSuccess, onError) => {
    record = record.set('displayName', record.get('fullName'));
    record = record.set('language', 'hu');

    const catch406 = (error) => {
      if (error && error.status === 406) {
        enqueueSnackbar(
            t('existing-name'),
            {variant: 'warning', persist: true, action: (key) => closeSnack(t, closeSnackbar, key)}
        );
        onError(error, true);
      } else {
        onError(error);
      }
    };

    const success = () => {
      navigate('/accounts/add/success');
    };

    addAccount(record, success, catch406);
  };

  return (
      <ContentBox path={path} form={form}>

        <Grid container direction={"column"} spacing={1}>

          <Grid item xs={12}> {/* form sections, both column */}

            <Grid container direction="row" spacing={2}> {/* columns grid */}

              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={1}>

                  <Grid item xs={12}>
                    <Section title={t('account-fields')} notes={t('account-fields-explanation')}>
                      <ValidatedInput name="principalName" autoComplete="username"/>
                      <ValidatedInput name="emailAddress" autoComplete="email"/>
                      {view === "add" ?
                          <React.Fragment>
                            <ValidatedInput name="credentials" password autoComplete="new-password"/>
                            <ValidatedInput name="verification" password validator={passwordValidator}/>
                          </React.Fragment>
                          :
                          <div className={classes.buttonContainer}>
                            <A href={"/accounts/" + id + "/password"}>
                              <Button color="success" className={classes.smallButton}>
                                <Lock className={classes.icons}/><span style={{marginLeft: 4}}>{t('password-change')}</span>
                              </Button>
                            </A>
                          </div>
                      }
                    </Section>
                  </Grid>


                  <Grid item xs={12}>
                    <Section title={t('required-fields')} notes={t('required-fields-explanation')}>
                      <ValidatedInput name="fullName" autoComplete="name"/>
                      <ValidatedDate name="birthDate"/>
                      <ValidatedInput name="address"/>
                      <ValidatedInput name="phoneNumber" autoComplete="tel"/>
                      <ValidatedRadioGroup name="gender">
                        <ValidatedRadio name="gender" value="FEMALE"/>
                        <ValidatedRadio name="gender" value="MALE"/>
                      </ValidatedRadioGroup>
                      <ValidatedRadioGroup name="tshirtSize">
                        <ValidatedRadio name="tshirtSize" value="S"/>
                        <ValidatedRadio name="tshirtSize" value="M"/>
                        <ValidatedRadio name="tshirtSize" value="L"/>
                        <ValidatedRadio name="tshirtSize" value="XL"/>
                      </ValidatedRadioGroup>
                    </Section>
                  </Grid>

                  <Grid item xs={12}>
                    <Section title={t('account:consent-title')}>
                      <ValidatedCheckbox name={"consent"}/>
                      {t('account:consent-content')}
                    </Section>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={1}>

                  <Grid item xs={12}>
                    <Section title={t('championship-fields')} notes={t('championship-fields-explanation')}>
                      <ValidatedInput name="taj"/>
                      <ValidatedInput name="birthName"/>
                      <ValidatedInput name="cardNumber"/>
                      <ValidatedSelect name="club" api={useClubs}/>
                      <ValidatedDate name="medicalExpiration"/>
                      <ValidatedRadioGroup name="classification">
                        <ValidatedRadio name="classification" value="amateur"/>
                        <ValidatedRadio name="classification" value="professional"/>
                      </ValidatedRadioGroup>
                    </Section>
                  </Grid>

                  <Grid item xs={12}>
                    <Section title={t('birth-fields')} notes={t('birth-fields-explanation')}>
                      <ValidatedInput name="birthPlace"/>
                      <ValidatedInput name="mothersName"/>
                      <ValidatedDate name="mothersBirthDate"/>
                      <ValidatedInput name="mothersMothersName"/>
                    </Section>
                  </Grid>

                  <Grid item xs={12}>
                    <Section title={t('invoicing-fields')} notes={t('invoicing-fields-explanation')}>
                      <ValidatedInput name="invoicingName"/>
                      <ValidatedInput name="invoicingAddress"/>
                      <ValidatedInput name="invoicingVATID"/>
                    </Section>
                  </Grid>

                  <Grid item xs={12}>
                    <Section title={t('account:settings')}>
                        <ValidatedCheckbox name={"showRegistration"}/>{t('showRegistration')}
                        <div>{t('showRegistration-explanation')}</div>
                      </Section>
                      </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <ValidatedSubmit api={view === "add" ? add : updateAccount} validationInfo={ValidationInfo} view={view} hideCancel/>
          </Grid>

        </Grid>
      </ContentBox>
  );
});