import { Record } from 'immutable';
import {date, lazy, mixed, number, object, string} from "yup";

export const AccountRecord = Record({
  accountId: 0, // used when updating an account

  principalName: '',
  emailAddress: '',
  credentials: '',
  verification: '',
  displayName: '',
  language: 'hu',

  fullName: '',
  birthDate: '',
  address: '',
  phoneNumber: '',
  gender: '',
  tshirtSize: '',

  consent: false,
  showRegistration: true,

  taj: '',
  birthName : '',

  // START - for testing
  // principalName: 'tiz',
  // emailAddress: 'tiz@simplexion.hu',
  // credentials: 'Almafa.12',
  // verification: 'Almafa.12',
  // displayName: '',
  // language: 'hu',
  //
  // fullName: 'Toth IStvan Zoltan',
  // birthDate: '1977-08-15',
  // address: 'Tompa u. 11',
  // phoneNumber: '111111111',
  // gender: 'male',
  // tshirtSize: 'M',
  //
  // consent: true,
  // END - for testing

  cardNumber: '',
  club: 0,
  medicalExpiration: null,
  classification: 'amateur',

  birthPlace: '',
  mothersName: '',
  mothersMothersName: '',
  mothersBirthDate: null,

  invoicingName : '',
  invoicingAddress: '',
  invoicingVATID: ''
});


export const AccountSchema = object().shape({
  principalName: string().required().min(3).max(50),
  emailAddress: string().required().email(),
  credentials: string().required().min(8).max(50),
  verification: string().required().min(8).max(50),

  fullName: string().required().min(3).max(60),
  birthDate: date().required(),
  address: string().required().min(9).max(60),
  phoneNumber: string().required().min(9).max(15),
  gender: mixed().required().oneOf(['MALE', 'FEMALE']),
  tshirtSize: mixed().required().oneOf(['S', 'M', 'L', 'XL']),
  consent: mixed().required().oneOf([true]),

  taj: lazy(value => ! value ? string().default('') : string().min(0).max(20).default('')),
  birthName: lazy(value => ! value ? string().default('') : string().min(5).max(60).default('')),

  showRegistration: mixed().required().oneOf([true, false]),

  birthPlace: lazy(value => ! value ? string().default('') : string().min(2).max(60).default('')),
  mothersName: lazy(value => ! value ? string().default('') : string().min(5).max(60).default('')),
  mothersMothersName: lazy(value => ! value ? string().default('') : string().min(5).max(60).default('')),
  mothersBirthDate: date().nullable(),

  cardNumber: lazy(value => ! value ? string().default('') : string().min(6).max(7)),
  club: number().min(0),
  medicalExpiration: date().nullable(),
  classification: mixed().required().oneOf(['amateur', 'professional']).default('amateur'),

  invoicingName: lazy(value => ! value ? string().default('') : string().min(5).max(60).default('')),
  invoicingAddress: lazy(value => ! value ? string().default('') : string().min(5).max(120).default('')),
  invoicingVATID: lazy(value => ! value ? string().default('') : string().min(5).max(20).default('')),
});

export const ResetRecord = Record({
  principalName: '',
  birthDate: ''
});

export const ResetSchema = object().shape({
  principalName: string().required().min(3).max(50),
  birthDate: date().required()
});

export const PasswordRecord = Record({
  oldPassword: '',
  newPassword: '',
  verification: ''
});

export const PasswordSchema = object().shape({
  oldPassword: string(),
  newPassword: string().required().min(3).max(50),
  verification: string().required().min(3).max(50)
});